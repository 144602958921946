import { createFileRoute } from '@tanstack/react-router'

import { HomeHouseStartIcon } from '@/components/icons/HomeHouseStartIcon'

export const Route = createFileRoute('/_private/')()

Route.interloom = {
  NavigationIcon: HomeHouseStartIcon,
  tooltipText: 'Home',
}
