/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n          mutation applyTaskRefinement($refinementId: ID!) {\n            applyTaskRefinement(input: { id: $refinementId }) {\n              id\n            }\n          }\n        ':
    types.ApplyTaskRefinementDocument,
  '\n          mutation CreateMessage($input: CreateMessageInput!) {\n            createMessage(input: $input) {\n              id\n              createdAt\n              threadId\n              author {\n                id\n                name\n              }\n              body {\n                text\n              }\n            }\n          }\n        ':
    types.CreateMessageDocument,
  '\n          mutation CreateTask($taskInput: CreateTaskInput!) {\n            createTask(input: $taskInput) {\n              id\n              title\n              createdAt\n            }\n          }\n        ':
    types.CreateTaskDocument,
  '\n          mutation DeleteTask($taskId: ID!) {\n            deleteTask(id: $taskId)\n          }\n        ':
    types.DeleteTaskDocument,
  '\n        query FetchAllUsers {\n          users {\n            id\n            name\n            avatarUrl\n          }\n        }\n      ':
    types.FetchAllUsersDocument,
  '\n        query FetchAllTasks {\n          tasks {\n            id\n            title\n            createdAt\n            completedAt\n            dueAt\n            assignee {\n              id\n              name\n              avatarUrl\n            }\n          }\n        }\n      ':
    types.FetchAllTasksDocument,
  '\n          query SimilarTasks($taskId: ID!) {\n            similarTasks(taskId: $taskId) {\n              task {\n                id\n                title\n                description\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n            }\n          }\n        ':
    types.SimilarTasksDocument,
  '\n          query TaskMessages($taskId: ID!) {\n            tasks(ids: [$taskId]) {\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                  type\n                  task {\n                    title\n                    description\n                  }\n                }\n              }\n            }\n          }\n        ':
    types.TaskMessagesDocument,
  '\n          query FetchTasksById($taskIds: [ID!]!) {\n            tasks(ids: $taskIds) {\n              id\n              title\n              createdAt\n              completedAt\n              dueAt\n              description\n              assigneeId\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  threadId\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                }\n              }\n              subtasks {\n                id\n                title\n                createdAt\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n              parentTask {\n                id\n                title\n                completedAt\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        ':
    types.FetchTasksByIdDocument,
  '\n          mutation OrderSubtasks(\n            $orderedSubtaskIds: [ID!]!\n            $insertAfterSubtaskWithId: ID\n          ) {\n            orderSubtasks(\n              input: {\n                orderedSubtaskIds: $orderedSubtaskIds\n                insertAfterSubtaskWithId: $insertAfterSubtaskWithId\n              }\n            ) {\n              subtasks {\n                id\n              }\n            }\n          }\n        ':
    types.OrderSubtasksDocument,
  '\n          mutation refineTask(\n            $taskId: ID!\n            $precedentTaskIds: [ID!]\n            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput\n          ) {\n            refineTask(\n              input: {\n                taskId: $taskId\n                precedentTaskIds: $precedentTaskIds\n                previousTaskRefinementFeedback: $previousTaskRefinementFeedback\n              }\n            ) {\n              id\n              updates {\n                change {\n                  parentTaskId\n                  title\n                }\n              }\n              precedentTasks {\n                assignee {\n                  name\n                  avatarUrl\n                }\n                id\n                title\n                completedAt\n                createdAt\n              }\n            }\n          }\n        ':
    types.RefineTaskDocument,
  '\n          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {\n            setTaskStatus(input: { id: $id, status: $status }) {\n              id\n              completedAt\n            }\n          }\n        ':
    types.SetTaskStatusDocument,
  '\n          mutation UpdateTask(\n            $id: ID!\n            $title: String\n            $description: String\n            $assigneeId: ID\n            $dueAt: DateTime\n            $parentTaskId: ID\n          ) {\n            updateTask(\n              input: {\n                id: $id\n                title: $title\n                description: $description\n                assigneeId: $assigneeId\n                dueAt: $dueAt\n                parentTaskId: $parentTaskId\n              }\n            ) {\n              id\n              title\n              description\n              assigneeId\n              dueAt\n            }\n          }\n        ':
    types.UpdateTaskDocument,
  '\n        query FetchViewer {\n          viewer {\n            id\n            name\n            avatarUrl\n          }\n        }\n      ':
    types.FetchViewerDocument,
  '\n          query FetchViewerWithTasks {\n            viewer {\n              id\n              name\n              avatarUrl\n              tasks {\n                id\n                title\n                createdAt\n                assigneeId\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        ':
    types.FetchViewerWithTasksDocument,
  '\n          query FetchActivityFlowData($taskId: ID!) {\n            getTaskActivityFlowData(taskId: $taskId) {\n              nodeId\n              parentId\n              adjacentNodes {\n                nodeId\n                activityCount\n              }\n              activityCount\n              summary\n            }\n          }\n        ':
    types.FetchActivityFlowDataDocument,
  '\n              query TestTasks {\n                tasks {\n                  id\n                }\n              }\n            ':
    types.TestTasksDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation applyTaskRefinement($refinementId: ID!) {\n            applyTaskRefinement(input: { id: $refinementId }) {\n              id\n            }\n          }\n        '
): (typeof documents)['\n          mutation applyTaskRefinement($refinementId: ID!) {\n            applyTaskRefinement(input: { id: $refinementId }) {\n              id\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation CreateMessage($input: CreateMessageInput!) {\n            createMessage(input: $input) {\n              id\n              createdAt\n              threadId\n              author {\n                id\n                name\n              }\n              body {\n                text\n              }\n            }\n          }\n        '
): (typeof documents)['\n          mutation CreateMessage($input: CreateMessageInput!) {\n            createMessage(input: $input) {\n              id\n              createdAt\n              threadId\n              author {\n                id\n                name\n              }\n              body {\n                text\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation CreateTask($taskInput: CreateTaskInput!) {\n            createTask(input: $taskInput) {\n              id\n              title\n              createdAt\n            }\n          }\n        '
): (typeof documents)['\n          mutation CreateTask($taskInput: CreateTaskInput!) {\n            createTask(input: $taskInput) {\n              id\n              title\n              createdAt\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation DeleteTask($taskId: ID!) {\n            deleteTask(id: $taskId)\n          }\n        '
): (typeof documents)['\n          mutation DeleteTask($taskId: ID!) {\n            deleteTask(id: $taskId)\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n        query FetchAllUsers {\n          users {\n            id\n            name\n            avatarUrl\n          }\n        }\n      '
): (typeof documents)['\n        query FetchAllUsers {\n          users {\n            id\n            name\n            avatarUrl\n          }\n        }\n      ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n        query FetchAllTasks {\n          tasks {\n            id\n            title\n            createdAt\n            completedAt\n            dueAt\n            assignee {\n              id\n              name\n              avatarUrl\n            }\n          }\n        }\n      '
): (typeof documents)['\n        query FetchAllTasks {\n          tasks {\n            id\n            title\n            createdAt\n            completedAt\n            dueAt\n            assignee {\n              id\n              name\n              avatarUrl\n            }\n          }\n        }\n      ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query SimilarTasks($taskId: ID!) {\n            similarTasks(taskId: $taskId) {\n              task {\n                id\n                title\n                description\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n            }\n          }\n        '
): (typeof documents)['\n          query SimilarTasks($taskId: ID!) {\n            similarTasks(taskId: $taskId) {\n              task {\n                id\n                title\n                description\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query TaskMessages($taskId: ID!) {\n            tasks(ids: [$taskId]) {\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                  type\n                  task {\n                    title\n                    description\n                  }\n                }\n              }\n            }\n          }\n        '
): (typeof documents)['\n          query TaskMessages($taskId: ID!) {\n            tasks(ids: [$taskId]) {\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                  type\n                  task {\n                    title\n                    description\n                  }\n                }\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query FetchTasksById($taskIds: [ID!]!) {\n            tasks(ids: $taskIds) {\n              id\n              title\n              createdAt\n              completedAt\n              dueAt\n              description\n              assigneeId\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  threadId\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                }\n              }\n              subtasks {\n                id\n                title\n                createdAt\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n              parentTask {\n                id\n                title\n                completedAt\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        '
): (typeof documents)['\n          query FetchTasksById($taskIds: [ID!]!) {\n            tasks(ids: $taskIds) {\n              id\n              title\n              createdAt\n              completedAt\n              dueAt\n              description\n              assigneeId\n              thread {\n                id\n                messages {\n                  id\n                  createdAt\n                  threadId\n                  author {\n                    id\n                    name\n                    avatarUrl\n                  }\n                  body {\n                    text\n                  }\n                }\n              }\n              subtasks {\n                id\n                title\n                createdAt\n                completedAt\n                assignee {\n                  id\n                  name\n                  avatarUrl\n                }\n              }\n              parentTask {\n                id\n                title\n                completedAt\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation OrderSubtasks(\n            $orderedSubtaskIds: [ID!]!\n            $insertAfterSubtaskWithId: ID\n          ) {\n            orderSubtasks(\n              input: {\n                orderedSubtaskIds: $orderedSubtaskIds\n                insertAfterSubtaskWithId: $insertAfterSubtaskWithId\n              }\n            ) {\n              subtasks {\n                id\n              }\n            }\n          }\n        '
): (typeof documents)['\n          mutation OrderSubtasks(\n            $orderedSubtaskIds: [ID!]!\n            $insertAfterSubtaskWithId: ID\n          ) {\n            orderSubtasks(\n              input: {\n                orderedSubtaskIds: $orderedSubtaskIds\n                insertAfterSubtaskWithId: $insertAfterSubtaskWithId\n              }\n            ) {\n              subtasks {\n                id\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation refineTask(\n            $taskId: ID!\n            $precedentTaskIds: [ID!]\n            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput\n          ) {\n            refineTask(\n              input: {\n                taskId: $taskId\n                precedentTaskIds: $precedentTaskIds\n                previousTaskRefinementFeedback: $previousTaskRefinementFeedback\n              }\n            ) {\n              id\n              updates {\n                change {\n                  parentTaskId\n                  title\n                }\n              }\n              precedentTasks {\n                assignee {\n                  name\n                  avatarUrl\n                }\n                id\n                title\n                completedAt\n                createdAt\n              }\n            }\n          }\n        '
): (typeof documents)['\n          mutation refineTask(\n            $taskId: ID!\n            $precedentTaskIds: [ID!]\n            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput\n          ) {\n            refineTask(\n              input: {\n                taskId: $taskId\n                precedentTaskIds: $precedentTaskIds\n                previousTaskRefinementFeedback: $previousTaskRefinementFeedback\n              }\n            ) {\n              id\n              updates {\n                change {\n                  parentTaskId\n                  title\n                }\n              }\n              precedentTasks {\n                assignee {\n                  name\n                  avatarUrl\n                }\n                id\n                title\n                completedAt\n                createdAt\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {\n            setTaskStatus(input: { id: $id, status: $status }) {\n              id\n              completedAt\n            }\n          }\n        '
): (typeof documents)['\n          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {\n            setTaskStatus(input: { id: $id, status: $status }) {\n              id\n              completedAt\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          mutation UpdateTask(\n            $id: ID!\n            $title: String\n            $description: String\n            $assigneeId: ID\n            $dueAt: DateTime\n            $parentTaskId: ID\n          ) {\n            updateTask(\n              input: {\n                id: $id\n                title: $title\n                description: $description\n                assigneeId: $assigneeId\n                dueAt: $dueAt\n                parentTaskId: $parentTaskId\n              }\n            ) {\n              id\n              title\n              description\n              assigneeId\n              dueAt\n            }\n          }\n        '
): (typeof documents)['\n          mutation UpdateTask(\n            $id: ID!\n            $title: String\n            $description: String\n            $assigneeId: ID\n            $dueAt: DateTime\n            $parentTaskId: ID\n          ) {\n            updateTask(\n              input: {\n                id: $id\n                title: $title\n                description: $description\n                assigneeId: $assigneeId\n                dueAt: $dueAt\n                parentTaskId: $parentTaskId\n              }\n            ) {\n              id\n              title\n              description\n              assigneeId\n              dueAt\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n        query FetchViewer {\n          viewer {\n            id\n            name\n            avatarUrl\n          }\n        }\n      '
): (typeof documents)['\n        query FetchViewer {\n          viewer {\n            id\n            name\n            avatarUrl\n          }\n        }\n      ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query FetchViewerWithTasks {\n            viewer {\n              id\n              name\n              avatarUrl\n              tasks {\n                id\n                title\n                createdAt\n                assigneeId\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        '
): (typeof documents)['\n          query FetchViewerWithTasks {\n            viewer {\n              id\n              name\n              avatarUrl\n              tasks {\n                id\n                title\n                createdAt\n                assigneeId\n                parentTask {\n                  id\n                }\n              }\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query FetchActivityFlowData($taskId: ID!) {\n            getTaskActivityFlowData(taskId: $taskId) {\n              nodeId\n              parentId\n              adjacentNodes {\n                nodeId\n                activityCount\n              }\n              activityCount\n              summary\n            }\n          }\n        '
): (typeof documents)['\n          query FetchActivityFlowData($taskId: ID!) {\n            getTaskActivityFlowData(taskId: $taskId) {\n              nodeId\n              parentId\n              adjacentNodes {\n                nodeId\n                activityCount\n              }\n              activityCount\n              summary\n            }\n          }\n        ']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n              query TestTasks {\n                tasks {\n                  id\n                }\n              }\n            '
): (typeof documents)['\n              query TestTasks {\n                tasks {\n                  id\n                }\n              }\n            ']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
