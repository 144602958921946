import { createFileRoute, RouteContext } from '@tanstack/react-router'
import { z } from 'zod'

import { NotFound } from '@/features/task/components/NotFound'
import { TaskBreadcrumb } from '@/features/task/components/TaskBreadcrumb'
import { createTaskQueryOptions, useTask } from '@/features/task/hooks/useTask'
import { createUsersQueryOptions } from '@/features/task/hooks/useUsers'

export const TASK_DETAILS_TABS = ['thread', 'flowchart', 'relevant'] as const

const parseParameters = (parameters: Record<'taskId', string>) => ({
  taskId: z.string().parse(parameters.taskId),
})

export interface TaskPageLoaderData {
  task: Awaited<ReturnType<RouteContext['api']['fetchTasksById']>>[number]
  allUsers: Awaited<ReturnType<RouteContext['api']['fetchAllUsers']>>['users']
}

export const Route = createFileRoute('/_private/all-work/$taskId')({
  loader: async ({ context, params }): Promise<TaskPageLoaderData> => {
    const client = context.queryClient

    const optionParameters = {
      api: context.api,
      taskId: params.taskId,
    }

    const taskOptions = createTaskQueryOptions(optionParameters)
    const usersOptions = createUsersQueryOptions({ api: context.api })

    const task = await client.ensureQueryData(taskOptions)
    const allUsers = await client.ensureQueryData(usersOptions)

    return {
      allUsers,
      task,
    }
  },
  notFoundComponent: NotFound,
  parseParams: parseParameters,
  validateSearch: z.object({
    tab: z.optional(z.enum(TASK_DETAILS_TABS)),
  }),
})

const Breadcrumb = () => {
  const { task } = useTask()

  return <TaskBreadcrumb task={task} />
}
Breadcrumb.displayName = 'TaskDetailBreadcrumb'

Route.interloom = {
  Breadcrumb,
}
